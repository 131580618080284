<template>
    <div class="home">
      <div style="
            height: 20px;
            line-height: 60px;
            right: 42px;
    position: fixed;
            text-align: right;">
            <i @click="handleSelect('/route/routelist')" class="el-icon-close"></i>
      </div>

      <div class="pt20"></div>
        <div class="form">
      <el-form ref="form" :model="form" label-width="180px">
          <el-form-item label="接口名称">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="接口地址">
            <el-input v-model="form.route"></el-input>
          </el-form-item>
          <el-form-item label="接口请求方式">
            <el-input v-model="form.method"></el-input>
          </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">确认</el-button>
          <el-button @click="goNav('/route/routelist')" >取消</el-button>
        </el-form-item>
      </el-form>
    </div>
      <div class="pt20"></div>
        
      
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      form: {
          name: '',
          remark: '',
        },
        options:[],
    }
  },
  methods: {
    handleSelect(url){
        this.$router.push(url)
    },
     onSubmit() {
      let _this = this;
      let postdata = this.form;
      axios.post(config.route,postdata)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: '编辑成功',
                type: 'success'
              });
              _this.goNav('/route/routelist');
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
      goNav(url){
        this.$router.push(url)
      },
    init(a){
        let obj = JSON.parse(a);
        this.form = obj;
    }
  },
  created(){
     let a = sessionStorage.getItem('edit');
      this.init(a);
  }
}
</script>
<style scoped>
.form{
    width: 500px;
    margin-left: 200px;
}
.el-icon-close:before{
  font-size: 24px;
}
</style>
